import { Controller } from "@hotwired/stimulus";
import HSSelect from "@preline/select";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    HSSelect.autoInit();
    this.element.addEventListener("change.hs.select", this.selected.bind(this));
  }

  selected(event) {
    const selectedValues = Array.from(this.selectTargets).map(
      (select) => select.value,
    );
  }

  disconnect() {
    // No need to manually destroy the Select
  }
}
