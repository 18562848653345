import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "submitButton",
    "buttonText",
    "spinner",
    "imageInput",
    "imagePreviews",
    "priceInDollars",
    "priceInCents",
    "cancelLink",
  ];

  connect() {
    this.imageInputTarget.addEventListener(
      "change",
      this.previewImages.bind(this),
    );

    if (this.cancelLinkTarget) {
      this.cancelLinkTarget.addEventListener(
        "click",
        this.closeSlideover.bind(this),
      );
    }
  }

  submit(event) {
    // Prevent double submission
    if (this.submitButtonTarget.disabled) {
      event.preventDefault();
      return;
    }

    const dollarAmount = parseFloat(this.priceInDollarsTarget.value);
    if (!isNaN(dollarAmount)) {
      const cents = Math.round(dollarAmount * 100);
      this.priceInCentsTarget.value = cents;
    } else {
      this.priceInCentsTarget.value = "";
    }

    this.submitButtonTarget.disabled = true;
    this.buttonTextTarget.textContent = "Creating...";
    this.spinnerTarget.classList.remove("hidden");

    this.element.submit();
  }

  previewImages(event) {
    this.imagePreviewsTarget.innerHTML = "";
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.type.startsWith("image/")) continue;

      const img = document.createElement("img");
      img.classList.add("h-24", "w-24", "rounded-md", "object-cover");
      img.file = file;

      const reader = new FileReader();
      reader.onload = ((aImg) => (e) => {
        aImg.src = e.target.result;
      })(img);
      reader.readAsDataURL(file);

      this.imagePreviewsTarget.appendChild(img);
    }
  }

  closeSlideover() {
    const dialog = this.element
      .closest('[data-controller="slideover"]')
      .querySelector('[data-slideover-target="dialog"]');
    if (dialog) {
      dialog.close();
    }
  }
}
