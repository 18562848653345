import { Controller } from "@hotwired/stimulus";
import HSComboBox from "@preline/combobox";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    HSComboBox.autoInit();
    this.element.addEventListener(
      "change.hs.combobox",
      this.selected.bind(this),
    );
  }

  selected(event) {
    const selectedValue = event.detail.value;
  }

  disconnect() {
    // No need to manually destroy the ComboBox
  }
}
